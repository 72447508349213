<template>
  <div id="app">
    <h-snow-animation />
    <router-view :key="$route.fullPath" />

    <the-navbar-mobile v-if="isMobile" />
    <the-navbar-desktop v-else />
    <the-modal-container />
    <the-alert-container />
  </div>
</template>

<script>
import TheAlertContainer from "@/components/app/TheAlertContainer";
import TheModalContainer from "@/components/app/TheModalContainer";
import TheNavbarDesktop from "@/components/app/TheNavbarDesktop";
import TheNavbarMobile from "@/components/app/TheNavbarMobile";
import HSnowAnimation from "@/components/general/HSnowAnimation";
import { AuthService } from "@/services/AuthService";

export default {
  name: "App",
  components: {
    HSnowAnimation,
    TheAlertContainer,
    TheNavbarDesktop,
    TheModalContainer,
    TheNavbarMobile,
  },
  computed: {
    isMobile() {
      return (
        (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ) ||
          visualViewport.width < 600) &&
        AuthService.isAdmin()
      );
    },
  },
};
</script>
