<template>
  <nav class="h-navbar" role="navigation" aria-label="main navigation">
    <router-link class="h-nav-header" :to="{ name: 'User' }">
      <figure class="image is-app-logo is-navbar-logo">
        <heaven-logo />
      </figure>
      <span>Heaven</span>
    </router-link>
    <template v-for="(item, index) in menuItems">
      <navigation-item :key="index" :item="item" />
    </template>
  </nav>
</template>

<script>
import HeavenLogo from "@/assets/heaven-logo.svg";
import NavigationItem from "@/components/navigation/NavigationItem";
import menuConfig from "@/config/menuConfig";

export default {
  name: "TheNavbarDesktop",
  components: {
    NavigationItem,
    HeavenLogo,
  },
  data() {
    return {
      isNavbarOpen: false,
    };
  },
  computed: {
    menuItems() {
      return menuConfig();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/variables";

.h-navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: $hTheme-navbar-background-color;
  padding: 0 $hTheme-navbar-padding 0;
  height: $hTheme-navbar-height;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.h-nav-header {
  color: $hTheme-navbar-text-color;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
</style>
