<template>
  <div class="scroll-indicator" :class="{ 'fade-out': !show }">
    <i class="chevron-down" />
  </div>
</template>

<script>
export default {
  name: "BouncingChevron",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-indicator {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;

  .chevron-down {
    display: block;
    width: 14px;
    height: 14px;
    border-style: solid;
    border-width: 3px 3px 0 0;
    border-color: rgba(255, 255, 255, 0.5);
    transform: rotate(135deg);
    animation: bounce 2s infinite;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: rotate(135deg) translateY(0);
  }
  40% {
    transform: rotate(135deg) translateY(-10px) translateX(10px);
  }
  60% {
    transform: rotate(135deg) translateY(-5px) translateX(5px);
  }
}

/* Fade out animation for indicator */
.scroll-indicator {
  transition: opacity 0.3s ease-out;

  &.fade-out {
    opacity: 0;
  }
}
</style>
