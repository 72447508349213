<template>
  <div class="h-nav-item">
    {{ item.label }}
    <component
      :is="item.decorator"
      v-if="item.decorator"
      v-bind="item.decorator.props"
    />
    <div class="h-nav-item-dropdown-up-container">
      <div
        v-for="subItem in item.menuItems"
        :key="subItem.label"
        class="h-nav-item-dropdown-up-item"
      >
        <router-link
          class="h-nav-item-dropdown-up-content main"
          :to="subItem.to"
        >
          {{ subItem.label }}
          <component
            :is="item.decorator"
            v-if="item.decorator"
            v-bind="item.decorator.props"
          />
        </router-link>
        <component
          :is="subItem.action.component"
          v-if="subItem.action"
          class="h-nav-item-dropdown-up-content"
          v-bind="subItem.action.props"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "@/components/navigation/MenuItem";
export default {
  name: "NavigationItemDropUp",
  props: {
    item: {
      type: MenuItem,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/variables";

.h-nav-item {
  color: $hTheme-navbar-text-color;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 $hTheme-navbar-item-padding 0;
  text-align: center;
  align-items: center;

  &:hover {
    background-color: $hTheme-navbar-background-color-active;

    .h-nav-item-dropdown-up-container {
      transform: scaleY(1);
    }
  }

  .router-link-exact-active {
    font-weight: bold;
  }
}

.h-nav-item-dropdown-up-container {
  position: absolute;
  bottom: 100%;
  left: 0;
  overflow: hidden;
  border-radius: $hTheme-navbar-radius $hTheme-navbar-radius 0 0;
  flex-direction: column;
  min-width: 100%;
  z-index: 10;
  display: flex;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.3s ease;
}

.h-nav-item-dropdown-up-item {
  background-color: $hTheme-navbar-background-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  width: 100%;
}

.h-nav-item-dropdown-up-content {
  background-color: $hTheme-navbar-background-color;
  height: $hTheme-navbar-height;
  left: 0;
  white-space: nowrap;
  padding: 0 $hTheme-navbar-item-padding 0;
  display: flex;
  align-items: center;
  font-weight: normal;

  &.main {
    flex-grow: 1;
    color: $hTheme-navbar-text-color;
  }

  &:hover {
    background-color: $hTheme-navbar-background-color-active;
    * {
      background-color: $hTheme-navbar-background-color-active;
    }
  }
}
</style>
