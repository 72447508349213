<template>
  <div id="icon" class="hamburger-icon" @click="onClick">
    <div class="burger-icon" :class="{ cross: isOpen }" />
    <div class="burger-icon" :class="{ cross: isOpen }" />
    <div class="burger-icon" :class="{ cross: isOpen }" />
    <div class="clear" />
  </div>
</template>

<script>
export default {
  name: "MenuIcon",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["click"],
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/variables";

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 $hTheme-navbar-item-padding 0;
}

.burger-icon {
  counter-increment: burger-icon;
  width: 32px;
  height: 2px;
  border-radius: 2px;
  background: $hTheme-navbar-text-color;
  transition: all 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
  animation-delay: 100ms;

  &:nth-child(1) {
    transform: translateY(-8px);
  }

  &:nth-child(3) {
    transform: translateY(8px);
  }

  &.cross {
    &:nth-child(1) {
      transform: rotate(40deg) translateY(1px) translateX(2px);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-40deg) translateY(-1px) translateX(2px);
    }
  }
}
</style>
