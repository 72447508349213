<template>
  <div v-if="item.menuItems.length > 0" class="h-nav-sidebar-list">
    <div class="h-nav-sidebar-item header">
      <div v-if="item.icon && isMobile" class="h-nav-icon">
        <the-icon :icon="item.icon" />
      </div>
      <div v-else class="h-nav-sidebar-link">
        {{ item.label }}
      </div>
      <component
        :is="item.decorator.component"
        v-if="item.decorator"
        v-bind="item.decorator.props"
      />
    </div>
    <div
      v-for="subItem in item.menuItems"
      :key="subItem.label"
      class="h-nav-sidebar-item"
    >
      <router-link
        class="h-nav-sidebar-link"
        :to="subItem.to"
        @click.native="closeSidebar"
      >
        <div v-if="subItem.icon && isMobile" class="h-nav-icon">
          <the-icon :icon="subItem.icon" />
        </div>
        <div v-else class="h-nav-sidebar-link">
          {{ subItem.label }}
        </div>
        <component
          :is="item.decorator.component"
          v-if="item.decorator"
          v-bind="item.decorator.props"
        />
      </router-link>
      <component
        :is="subItem.action.component"
        v-if="subItem.action"
        class="h-nav-item"
        v-bind="subItem.action.props"
      />
    </div>
  </div>
  <div v-else class="h-nav-sidebar-item">
    <router-link
      :to="item.to"
      class="h-nav-sidebar-link"
      @click.native="closeSidebar"
    >
      {{ item.label }}
      <component
        :is="item.decorator.component"
        v-if="item.decorator"
        v-bind="item.decorator.props"
      />
    </router-link>
  </div>
</template>

<script>
import TheIcon from "@/components/general/TheIcon";
import MenuItem from "@/components/navigation/MenuItem";
export default {
  name: "CollapsibleNavItem",
  components: { TheIcon },
  props: {
    item: {
      type: MenuItem,
      required: true,
    },
  },
  emits: ["close"],
  computed: {
    isMobile() {
      return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ) || visualViewport.width < 600
      );
    },
  },
  methods: {
    closeSidebar() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/variables";

.h-nav-sidebar-item {
  color: $hTheme-navbar-text-color;
  height: $hTheme-navbar-height;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 $hTheme-navbar-item-padding 0;

  &.header {
    background-color: $hTheme-navbar-background-color-active;
  }

  &.router-link-exact-active {
    font-weight: bold;
  }
}

.h-nav-sidebar-link {
  color: $hTheme-navbar-text-color;
  display: flex;
  position: relative;
  height: $hTheme-navbar-height;
  flex-grow: 1;
  align-items: center;
  text-align: center;
}

.h-nav-sidebar-list {
  display: flex;
  flex-direction: column;

  .h-nav-sidebar-link {
    padding: 0 $hTheme-navbar-item-padding 0;
  }
}
</style>
