<template>
  <div
    v-show="count > 0"
    class="notification-counter"
    :class="{ animate: isAnimating }"
  >
    {{ count }}
  </div>
</template>

<script>
import { SupportRequestsFilter as SupportRequestFilterClass } from "@/models/SupportRequestsFilter";
import { SupportRequestStatus } from "@/models/documentModels/SupportRequest";
import { ReactiveService } from "@/services/ReactiveService";
import { SupportRequestsService } from "@/services/SupportRequestsService";
import { SupportRequestsUtils } from "@/utils/SupportRequestsUtils";

export default {
  name: "NotificationCounter",

  data() {
    return {
      count: 0,
      isAnimating: false,
      filter: ReactiveService.getReactive(
        "SupportRequestsFilter",
        new SupportRequestFilterClass(),
      ),
    };
  },

  watch: {
    count(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.triggerAnimation();
      }
    },
  },

  mounted() {
    SupportRequestsService.addObserver(this.updateCount);
    this.updateCount();
  },

  destroyed() {
    SupportRequestsService.removeObserver(this.updateCount);
  },

  methods: {
    async updateCount() {
      const requests =
        await SupportRequestsUtils.getAllFromSupportRequestsFilter(this.filter);
      this.count = requests.filter((request) => {
        return [
          SupportRequestStatus.PENDING,
          SupportRequestStatus.CREATED,
        ].includes(request.status);
      }).length;
    },

    triggerAnimation() {
      this.isAnimating = true;
      setTimeout(() => {
        this.isAnimating = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-counter {
  position: absolute;
  top: -2px;
  right: 2px;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  background-color: #e11a04;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  transition: transform 0.3s ease;

  &.animate {
    transform: scale(1.2);
  }

  @media screen and (max-width: 1024px) {
    right: 7px;
  }

  @media screen and (max-width: 768px) {
    top: 3px;
    right: 2px;
    min-width: 15px;
    height: 15px;
    font-size: 11px;
    line-height: 15px;
  }
}
</style>
