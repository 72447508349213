import axios from "axios";
import { getAuth } from "firebase/auth";

const timeout = 2000;

function toURL(path) {
  return `https://${path}`;
}

export async function printQR(assetId, office) {
  try {
    const accessToken = await getAuth().currentUser.getIdToken();
    const response = await axios.post(
      toURL(office.configuration.qrPrinterAddress) + "/print",
      {
        qrData: toURL("heaven.softhouselabs.com/#/asset/qr/") + assetId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        timeout: timeout,
      },
    );
    return response.status === 200;
  } catch (err) {
    return false;
  }
}

export async function testQRPrinterConnection(path) {
  try {
    const response = await axios.get(toURL(path) + "/ready", {
      timeout: timeout,
    });
    return response.status === 200;
  } catch (error) {
    return false;
  }
}
