<template>
  <navigation-item-drop-up v-if="item.menuItems.length > 0" :item="item" />
  <div v-else class="h-nav-item">
    <router-link v-if="item.to" :to="item.to" class="h-nav-link">
      <div v-if="item.icon && isMobile" class="h-nav-icon">
        <the-icon :icon="item.icon" />
      </div>
      <div v-else class="h-nav-sidebar-link">
        {{ item.label }}
      </div>
      <component
        :is="item.decorator.component"
        v-if="item.decorator"
        v-bind="item.decorator.props"
      />
    </router-link>
    <component
      :is="item.action.component"
      v-if="item.action"
      class="h-nav-item"
      v-bind="item.action.props"
    />
  </div>
</template>

<script>
import TheIcon from "@/components/general/TheIcon";
import MenuItem from "@/components/navigation/MenuItem";
import NavigationItemDropUp from "@/components/navigation/NavigationItemDropUp";

export default {
  name: "NavigationItem",
  components: { TheIcon, NavigationItemDropUp },
  props: {
    item: {
      type: MenuItem,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ) || visualViewport.width < 600
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/variables";
.h-nav-item:deep(.button) {
  background-color: $hTheme-navbar-button-success;

  &:hover {
    background-color: $hTheme-navbar-button-success-hover;
  }
}

.h-nav-item {
  background-color: $hTheme-navbar-background-color;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 $hTheme-navbar-item-padding 0;
  text-align: center;
  align-items: center;

  &:hover {
    background-color: $hTheme-navbar-background-color-active;
  }

  .router-link-exact-active {
    font-weight: bold;
  }

  .h-nav-link {
    color: $hTheme-navbar-text-color;
  }
}
</style>
