<template>
  <nav class="h-navbar" role="navigation" aria-label="main navigation">
    <router-link class="h-nav-header" :to="{ name: 'User' }">
      <figure class="image is-app-logo is-navbar-logo">
        <heaven-logo />
      </figure>
      <span>Heaven</span>
    </router-link>
    <menu-icon :is-open="isNavbarOpen" @click="toggleNavbar" />
    <div class="sidenav-container" :class="{ open: isNavbarOpen }">
      <div class="sidenav">
        <template v-for="(item, index) in menuItems">
          <collapsible-nav-item
            :key="index"
            :ref="'item-' + index"
            :item="item"
            @visibility-change="(isVisible) => setChevron(isVisible)"
            @close="toggleNavbar"
          />
        </template>
      </div>
      <bouncing-chevron :show="showChevron" />
    </div>
  </nav>
</template>

<script>
import HeavenLogo from "@/assets/heaven-logo.svg";
import BouncingChevron from "@/components/app/BouncingChevron";
import CollapsibleNavItem from "@/components/app/CollapsibleNavItem";
import MenuIcon from "@/components/app/MenuIcon";
import menuConfig from "@/config/menuConfig";
import { visibilityMixin } from "@/mixins/VisibilityMixin";

export default {
  name: "TheNavbarMobile",
  components: {
    BouncingChevron,
    CollapsibleNavItem,
    MenuIcon,
    HeavenLogo,
  },
  mixins: [visibilityMixin],
  data() {
    return {
      isNavbarOpen: false,
      showChevron: false,
    };
  },
  computed: {
    menuItems() {
      return menuConfig();
    },
  },
  mounted() {
    const lastIndex = this.menuItems.length - 1;
    this.$nextTick(() => {
      this.setupTracking(lastIndex);
    });
  },
  methods: {
    setupTracking(index) {
      this.initializeVisibilityTracking("item-" + index);
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    setChevron(isVisible) {
      this.showChevron = !isVisible;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/variables";
.h-navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: $hTheme-navbar-background-color;
  padding: 0 $hTheme-navbar-padding 0;
  height: $hTheme-navbar-height;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.h-nav-header {
  color: $hTheme-navbar-text-color;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.sidenav-container {
  position: absolute;
  width: 100vw;
  height: calc(100vh - $hTheme-navbar-height);
  bottom: $hTheme-navbar-height;
  right: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);

  &.open {
    transform: scaleY(1);
  }
}

.sidenav {
  height: 100%;
  background-color: $hTheme-navbar-background-color;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

@media screen and (min-width: $hTheme-sidebar-fullscreen-breakpoint) {
  .sidenav-container {
    width: auto;
    min-width: 300px;
  }
}
</style>
