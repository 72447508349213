import AddAssetButton from "@/components/assets/AddAssetButton";
import AddKeyButton from "@/components/keys/AddKeyButton";
import MenuItem from "@/components/navigation/MenuItem";
import AddOfficeGroupButton from "@/components/office-groups/AddOfficeGroupButton";
import AddOfficeButton from "@/components/offices/AddOfficeButton";
import AddResourceButton from "@/components/resources/AddResourceButton";
import AddRetailerButton from "@/components/retailers/AddRetailerButton";
import NumberOfNotifications from "@/components/widget/NumberOfNotifications";
import { AuthService } from "@/services/AuthService";

const adminMenu = new MenuItem({
  label: "Admin",
  menuItems: [
    new MenuItem({
      label: "Offices",
      to: { name: "Offices" },
    }),
    new MenuItem({
      label: "Retailers",
      to: { name: "Retailers" },
      action: {
        component: AddRetailerButton,
      },
    }),
    new MenuItem({
      label: "Resources",
      to: { name: "Resources" },
      action: {
        component: AddResourceButton,
      },
    }),
  ],
});

const superAdminMenu = new MenuItem({
  label: "Admin",
  menuItems: [
    new MenuItem({
      label: "Office Groups",
      to: { name: "OfficeGroups" },
      action: {
        component: AddOfficeGroupButton,
      },
    }),
    new MenuItem({
      label: "Offices",
      to: { name: "Offices" },
      action: {
        component: AddOfficeButton,
      },
    }),
    new MenuItem({
      label: "Retailers",
      to: { name: "Retailers" },
      action: {
        component: AddRetailerButton,
      },
    }),
    new MenuItem({
      label: "Resources",
      to: { name: "Resources" },
      action: {
        component: AddResourceButton,
      },
    }),
  ],
});

const inventoryMenu = new MenuItem({
  label: "Inventory",
  requiresAdmin: true,
  menuItems: [
    new MenuItem({
      label: "Library",
      to: { name: "Library" },
    }),
    new MenuItem({
      label: "Assets",
      to: { name: "Assets" },
      action: {
        component: AddAssetButton,
      },
    }),
  ],
});

// Access Menu
const accessMenu = new MenuItem({
  label: "Access",
  menuItems: [
    new MenuItem({
      label: "Keys",
      to: { name: "Keys" },
      action: {
        component: AddKeyButton,
      },
    }),
    new MenuItem({
      label: "Users",
      to: { name: "Users" },
    }),
  ],
});

// Standard Menu Items
const standardMenuItems = [
  new MenuItem({
    icon: "ion:library",
    label: "Library",
    to: { name: "Library" },
  }),
  new MenuItem({
    icon: "raphael:user",
    label: "Profile",
    to: { name: "User" },
  }),
  new MenuItem({
    label: "", // Empty label for add asset button
    action: {
      component: AddAssetButton,
    },
  }),
];

const remainder = [
  new MenuItem({
    label: "Support Requests",
    to: { name: "SupportRequests" },
    decorator: {
      component: NumberOfNotifications,
    },
  }),
  new MenuItem({
    label: "Profile",
    to: { name: "User" },
  }),
  new MenuItem({
    label: "Dashboard",
    to: { name: "Dashboard" },
  }),
];

const superAdminMenuItems = [
  superAdminMenu,
  inventoryMenu,
  accessMenu,
  ...remainder,
];

const adminMenuItems = [adminMenu, inventoryMenu, accessMenu, ...remainder];

function menuConfig() {
  if (AuthService.isSuperAdmin()) {
    return superAdminMenuItems;
  } else if (AuthService.isAdmin()) {
    return adminMenuItems;
  } else {
    return standardMenuItems;
  }
}

export default menuConfig;
