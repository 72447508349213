export const visibilityMixin = {
  data() {
    return {
      visibilityObserver: null,
      initializeAttempts: 0,
    };
  },

  methods: {
    /**
     * Initialize visibility tracking for an element with retry logic
     * @param {string} refName - The ref name to track
     * @param {Object} options - Configuration options
     */
    initializeVisibilityTracking(refName, options = {}) {
      const defaultOptions = {
        threshold: 0.5,
        root: null,
        rootMargin: "0px",
        maxAttempts: 10,
        retryDelay: 200,
      };

      const config = { ...defaultOptions, ...options };

      const tryInitialize = () => {
        let element = this.$refs[refName];

        if (Array.isArray(element)) {
          element = element[0].$el;
        }

        if (!element) {
          this.initializeAttempts++;
          if (this.initializeAttempts < config.maxAttempts) {
            setTimeout(() => tryInitialize(), config.retryDelay);
            return;
          }
          console.warn(
            `Failed to initialize visibility tracking for ref "${refName}" after ${config.maxAttempts} attempts`,
          );
          return;
        }

        if (this.visibilityObserver) {
          this.visibilityObserver.disconnect();
        }

        this.visibilityObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              const element = this.$refs[refName]?.[0];
              element.$emit("visibility-change", entry.isIntersecting);
            });
          },
          {
            threshold: config.threshold,
            root: config.root,
            rootMargin: config.rootMargin,
          },
        );

        try {
          this.visibilityObserver.observe(element);
          this.initializeAttempts = 0; // Reset attempts counter on success
        } catch (error) {
          console.error("Error setting up visibility observer:", error);
        }
      };

      this.$nextTick(tryInitialize);
    },

    /**
     * Clean up the visibility observer
     */
    cleanupVisibilityTracking() {
      if (this.visibilityObserver) {
        this.visibilityObserver.disconnect();
        this.visibilityObserver = null;
      }
      this.initializeAttempts = 0;
    },
  },

  beforeDestroy() {
    this.cleanupVisibilityTracking();
  },
};
