/**
 * @typedef {Object} RouteLocation
 * @property {string} name - Route name
 * @property {Object} [params] - Route parameters
 * @property {Object} [query] - Route query parameters
 */

/**
 * @typedef {Object} Component
 * @property {Object} component - Vue component definition
 * @property {Object} [props] - Props to pass to the component
 */

/**
 * Class representing a navigation menu item
 */
class MenuItem {
  /**
   * Create a menu item
   * @param {Object} config - The menu item configuration
   * @param {string} config.label - Display label for the menu item
   * @param {RouteLocation} [config.to] - Vue Router location object
   * @param {Component} config.decorator - Optional component to render alongside the label
   * @param {string} [config.icon] - Icon for the menu item
   * @param {Component} [config.action] - Optional button configuration
   * @param {MenuItem[]} [config.menuItems=[]] - Array of nested MenuItem instances
   */
  constructor({
    label,
    to = null,
    decorator = null,
    icon = null,
    action = null,
    menuItems = [],
  }) {
    this.label = label;
    this.to = to;
    this.decorator = decorator;
    this.icon = icon;
    this.action = action;
    this.menuItems = menuItems.filter((item) => item instanceof MenuItem);
  }
}

export default MenuItem;
