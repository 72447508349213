<template>
  <div>
    <div v-if="printing" class="control column is-align-content-center">
      <h-message is-dark>
        <template #title> Printing</template>
        <progress class="progress is-large" max="100" show-value="true" />
      </h-message>
    </div>
    <div v-else class="field is-grouped">
      <div v-if="!printed" class="control">
        <button
          id="printQrCode"
          type="button"
          class="button is-warning mr-2"
          :disabled="!selectedPrintingOffice"
          @click="onClickPrintQrCode"
        >
          Print
        </button>
      </div>
      <div v-else class="control is-align-content-center">
        <div id="printedTag" class="tag is-success is-medium">Printed</div>
      </div>
      <div class="control">
        <div class="select">
          <select id="officePrinterSelector" v-model="selectedPrintingOffice">
            <option :value="null" disabled selected>Select printer</option>
            <option
              v-for="printer in officesWithPrinterConfig"
              :key="printer.CRV"
              :value="printer"
            >
              {{ printer.HRV }}
            </option>
          </select>
        </div>
      </div>
      <div class="control">
        <button
          id="scanQRButton"
          type="button"
          class="button is-warning"
          @click="onClickQrScanButton"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
    <h-message v-if="error || printerError" id="printerError" is-blob>
      <template #title>Error</template>
      <template #right-title>
        <button
          class="button is-small is-ghost"
          type="button"
          @click="resetErrors"
        >
          <the-icon icon="mdi:close" size="15" />
        </button>
      </template>
      <template v-if="printerError" #default>
        <p>Couldn't print QR code, make sure that:</p>
        <p>- Your office has a qr-printer set up</p>
        <p>- You are on the same local network as the office qr-printer</p>
        <p>- The office qr-printer server is running</p>
      </template>
      <template v-else #default>
        {{ error }}
      </template>
    </h-message>
    <qrcode-stream
      v-if="cameraIsActive"
      id="qrScannerStream"
      @decode="onDecode"
      @init="onInit"
    />
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import HMessage from "@/components/general/HMessage";
import TheIcon from "@/components/general/TheIcon";
import { CacheService } from "@/services/CacheService";
import { printQR } from "@/utils/QrPrinterUtil";

export default {
  name: "QrReader",
  components: {
    TheIcon,
    HMessage,
    QrcodeStream,
  },
  props: {
    qrCode: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      officesWithPrinterConfig: null,
      selectedPrintingOffice: null,
      error: "",
      cameraIsActive: false,
      openPrinterSettings: false,
      printing: false,
      printed: false,
      printingDestination: "",
      printerError: false,
    };
  },
  computed: {
    buttonText() {
      return this.cameraIsActive ? "Deactivate camera" : "Scan QR";
    },
  },
  mounted() {
    this.officesWithPrinterConfig = CacheService.getOffices().filter(
      (office) => office?.configuration?.qrPrinterAddress,
    );
  },
  methods: {
    onDecode(result) {
      const value = result?.split("/")?.pop();
      if (value) {
        this.$emit("set-qr-code", value);
        this.onClickQrScanButton();
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        this.cameraIsActive = false;
        if (error.name === "NotAllowedError") {
          this.error = "You need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "No camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "Secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "Is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "Installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "Stream API is not supported in this browser";
        }
      }
    },
    onClickQrScanButton() {
      this.resetErrors();
      this.cameraIsActive = !this.cameraIsActive;
    },
    async onClickPrintQrCode() {
      this.resetErrors();
      this.cameraIsActive = false;
      const assetId = this.qrCode || crypto.randomUUID();
      try {
        this.printing = true;
        this.printerError = !(await printQR(
          assetId,
          this.selectedPrintingOffice,
        ));
        if (!this.printerError) {
          this.$emit("set-qr-code", assetId);
          this.printed = true;
        }
      } catch (err) {
        this.printerError = true;
      } finally {
        this.printing = false;
      }
    },
    resetErrors() {
      this.printerError = false;
      this.error = "";
    },
  },
};
</script>
